@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
body {
  margin: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  min-height: 100vh;
  font-size: 1.25em;
}

.header-logo {
  height: 15vmin;
  pointer-events: none;
}

.footer-logo {
  height: 10vmin;
  pointer-events: none;
}

.contact-icon-layout {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contact-icon {
  margin: 5px 10px 25px 10px;
}

.address {
  margin: 10px;
}

.maps-iframe {
  margin: 0px 0px 0px 0px;
}

.address-text {
  font-style: italic;
}

.phone {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 0px;
}

.phone-icon {
  height: 10vh;
  margin: 0px 5px;
}

.phone-icon-footer {
  height: 5vh;
  margin: 0px 0px 0px 0px;
  align-items: center;
}

.phone-number-footer {
  font-size: 1em;
}

.contact-header {
  /* background-color: rgba(15, 35, 102, 0.836); */
  /* color: white; */
}

.contact-text {
  margin: 2px 0px 2px 0px;
}

.home-image {
  min-height: 30vh;
}

